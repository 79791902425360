import { IError } from '../api/globalResponses'

const apiIds = {
  'user-access-api': 5,
  'wfm-location-api': 10,
  'wfm-accrual-api': 28,
}

const errorConfig: Record<string, { allowedCodes: string[]; genericMessage: string }> = {
  [apiIds['user-access-api']]: {
    allowedCodes: ['11'],
    genericMessage: 'Something went wrong. Please try again.',
  },
  [apiIds['wfm-location-api']]: {
    allowedCodes: ['11'],
    genericMessage: 'Something went wrong. Please try again.',
  },
  [apiIds['wfm-accrual-api']]: {
    allowedCodes: ['11'],
    genericMessage: 'Error loading accrual data',
  },
}

const unknownError = {
  code: 'unknown',
  message: 'An unknown error occurred. Please try again later.',
}

export function processError(error?: IError): IError {
  if (!error || !error.code || !error.message) {
    return unknownError
  }

  const [apiId, errorCode] = error.code.split('-').slice(1) // Extract API and error code

  if (!apiId || !errorCode) {
    return unknownError
  }

  const apiConfig = errorConfig[apiId]

  let newMessage: string

  if (apiConfig) {
    if (apiConfig.allowedCodes.includes(errorCode)) {
      newMessage = error.message // Use the original API message
    } else {
      newMessage = apiConfig.genericMessage // Use the generic message for that API
    }
  } else {
    newMessage = 'An unknown error occurred. Please try again later.' // Default generic message
  }

  // Return a new IError object with the updated message
  return {
    ...error,
    message: newMessage,
  }
}
