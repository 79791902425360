import { Typography } from '@mui/material'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { useUser } from '../../../auth/hooks/useUser'
import MyProtectedElement from '../../../auth/MyProtectedElement'
import { updateRequestDateRange } from '../../../store/requestTimeOff/actionCreator'
import AccrualInfo from '../../accruals/AccrualInfo'
import DateRangePicker from '../../common/calendar/DateRangePicker'
import AccrualInfoMessage from '../../accruals/AccrualInfoMessage'

export default function RequestHeader() {
  const { userData, locationData } = useUser().user
  const dispatch = useDispatch()
  const minDate = useSelector((store) => store.requestTimeOff?.requestConfig?.header.minDate)
  const maxDate = useSelector((store) => store.requestTimeOff?.requestConfig?.header.maxDate)

  const handleDateOnChange = (name, date, state) => {
    dispatch(updateRequestDateRange(state.startDate, state.endDate))
  }

  if (locationData.iso_time_zone_code) {
    let validMinDate = moment(minDate).tz(locationData.iso_time_zone_code).toDate()
    let validMaxDate = moment(maxDate).tz(locationData.iso_time_zone_code).toDate()

    return (
      <>
        <DateRangePicker
          minDate={validMinDate}
          maxDate={validMaxDate}
          maxDaysInRange={14}
          onBlurMessage={true}
          handleOnChange={handleDateOnChange}
          containerSx={{ padding: '10px', justifyContent: 'space-evenly', alignItems: 'flex-start' }}
          textFieldSx={{ maxWidth: '126px' }}
          pickerJoinComponent={<Typography sx={{ fontSize: '16px', padding: '16px 8px 0px 8px' }}>to</Typography>}
        />
        <MyProtectedElement allowed={['accrual']} renderUnauthorized={() => <></>}>
          <AccrualInfoMessage />
          <AccrualInfo workerId={userData.worker_id} locationId={locationData.location_id} />
        </MyProtectedElement>
      </>
    )
  }

  return <></>
}
