import { useAuth } from '@praxis/component-auth'
import { useQuery } from '@tanstack/react-query'
import { formatDateString, formatHoursToDisplay } from '../../utils/DateUtil'
import { getAccruals } from './api/access'

export function useAccruals(worker_id: string, location_id: string) {
  const { isAuthenticated } = useAuth()

  const authenticated = isAuthenticated()

  const data = useQuery({
    queryKey: ['accruals', worker_id, location_id],
    queryFn: () => getAccruals({ worker_id, location_id }),
    staleTime: 1000 * 15, // 15 seconds
    enabled: authenticated && !!worker_id,
    retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 15000),
    keepPreviousData: true,
  })

  let adjustedVacation = formatHoursToDisplay(data?.data?.adjusted_vac_balance, 'small')
  let adjustedSick = formatHoursToDisplay(data?.data?.adjusted_sick_balance, 'small')
  let plannedVacation = formatHoursToDisplay(data?.data?.planned_vac_balance, 'small')
  let plannedSick = formatHoursToDisplay(data?.data?.planned_sick_balance, 'small')

  let totalAvailableVacation = formatHoursToDisplay(data?.data?.total_available_vac, 'small')
  let totalAvailableSick = formatHoursToDisplay(data?.data?.total_available_sick, 'small')

  let asOfDate = formatDateString(data?.data?.as_of_date, 'YYYY-MM-DD', 'MMM DD, YYYY')

  return {
    ...data,
    adjustedVacation,
    adjustedSick,
    plannedVacation,
    plannedSick,
    totalAvailableVacation,
    totalAvailableSick,
    asOfDate,
  }
}
