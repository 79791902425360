import axios from 'axios'
import apiConfig from '../../../config/apiConfig'
import { IAccruals } from '../responses/accruals'

export interface AccrualsQueryParams {
  worker_id: string
  location_id: string
}

export const getAccruals = (params: AccrualsQueryParams): Promise<IAccruals> => {
  let apiUrl = `${apiConfig.accruals.url}` + '/accruals?key=' + `${apiConfig.accruals.key}`

  return axios.get(apiUrl, { params }).then((response) => response.data)
}
