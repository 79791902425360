import { Box, IconButton, Typography } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import Popover from '@mui/material/Popover'
import moment from 'moment-timezone'
import { Close } from '@mui/icons-material'

export default function AccrualInfoMessage() {
  const [anchorEl, setAnchorEl] = useState(null)
  const [isPopoverVisible, setPopoverVisibility] = useState(false)
  const anchorRef = useRef(null)

  const handleOpen = () => {
    setAnchorEl(anchorRef.current)
    setPopoverVisibility(true)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setPopoverVisibility(false)
  }

  useEffect(() => {
    const currentDate = new Date()
    const currentDateInCT = moment(currentDate).tz('America/Chicago').format()
    const dayOfWeek = currentDate.getDay()
    const hoursInCT = moment(currentDateInCT).hour()

    // Tuesday, between 6am and 10am.
    if (dayOfWeek === 2 && hoursInCT >= 6 && hoursInCT < 10) {
      handleOpen()
    }
  }, [])

  return (
    <div ref={anchorRef}>
      <Popover
        id="accruals-popover"
        open={isPopoverVisible}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={handleClose}
        disableRestoreFocus
        sx={{ margin: 2, marginTop: '50px' }}
      >
        <IconButton aria-label="close" onClick={handleClose} sx={{ position: 'absolute', top: 1, right: 1 }}>
          <Close />
        </IconButton>
        <Box
          sx={{ maxWidth: '500px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
          m={5}
          display="flex"
        >
          <Typography>
            Your balances are currently being updated so they may not be accurate. Please come back after 10AM Central
            Time to see your updated available balance.
          </Typography>
        </Box>
      </Popover>
    </div>
  )
}
